import Vue from 'vue'
import CertifiedSuc from './Index.vue'

// 这里可以用Vue.extend()创建子类，但是我没打算注册为组件，就直接`new Vue`
const CertifiedSucDialog = new Vue(CertifiedSuc)
CertifiedSucDialog.$mount(document.createElement('div'))
document.body.appendChild(CertifiedSucDialog.$el)

Vue.prototype.$CertifiedSucDialog = CertifiedSucDialog

export default CertifiedSucDialog
