const state = {
  user: null, // user 信息存在则为已经登录， 为null则为没有登录
  amapIsReady: false, // 高德地图 JSAPI 是否已加载完成: true-已加载; false-未加载
  // 当前登录用户基本位置信息
  userLocationInfo: {
    longitude: 120.186164, // 经度（杭州东新路）
    latitude: 30.32465, // 纬度（杭州东新路）
    // country: '中国', // 国家
    // province: '浙江省', // 省
    // city: '杭州市', // 市
    // district: '拱墅区', // 区/县
    // township: '东新街道', // 街道/镇
    // adcode: '330105', // 所在地行政区划代码
    // formattedAddress: '浙江省杭州市拱墅区东新街道新天地商务中心11幢南6层', // 详细地址
  },
  mapboxInstance: null, // 存储mapbox实例，用于离开页面时销毁
  localInfoArr: [], // 存储产品详情页面-当前位置信息
}

export default state
