import request from '@/fetch/request'

const actions = {
  // 获取用户是否认证成功
  async getUserhasCertified({ commit }) {
    // 判断用户是否认证成功 成功则弹窗
    try {
      const res = await request.get('/authentication/status')
      // 是否认证商家 1：未认证，2：已认证，3：未认证但已提交认证申请
      commit('user/SET_HASCERTIFIED', res.authenticationStatus)
      // 是否需要弹窗
      return res.isPopup
    } catch (err) {
      console.log(err.message)
      return false
    }
  },
}
export default actions
