const state = {
  mobile: '', //手机号
  token: '', //用户token
  hasCertified: 1, // 是否认证商家 1：未认证，2：已认证，3：未认证但已提交认证申请
  roleEntities: null, //角色信息
}

const mutations = {
  // 设置登录token
  SET_TOKEN: (state, payload) => {
    state.token = payload
  },
  // 设置用户手机号码
  SET_MOBILE: (state, payload) => {
    state.mobile = payload
  },
  // 设置用户角色
  SET_ROLE_ENTITIES: (state, payload) => {
    state.roleEntities = payload
  },
  // 清除用户信息
  REMOVE_USER_INFO: (state) => {
    state.token = null
    state.mobile = null
    state.roleEntities = null
    state.hasCertified = 1
  },
  // 修改认证商家状态
  SET_HASCERTIFIED: (state, payload) => {
    state.hasCertified = payload
  },
}

export default {
  name: 'user',
  namespaced: true,
  state,
  mutations,
}
