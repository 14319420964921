<template>
  <el-dialog title="认证商家" :visible.sync="dialogVisible" width="560px" :before-close="handleClose">
    <div class="dialog-body">
      <h3>亲爱的 {{ store.state.user.mobile }} 您好，您的身份认证已完成</h3>
      <h3>恭喜成为担担网的认证商家！</h3>
      <div class="tips">您发布的供应信息将会带有<img src="../../assets/images/home/real_name.png" />标识</div>
    </div>
  </el-dialog>
</template>
<script>
import store from '@/store'
export default {
  data() {
    return {
      dialogVisible: false,
      store,
    }
  },
  methods: {
    // 关闭弹窗后需要更新认证完成弹窗状态
    async handleClose() {
      try {
        await this.$request.post('/authentication/updatePopupStatus')
      } catch (err) {
        console.log(err.message)
      }
      this.dialogVisible = false
    },

    open() {
      this.dialogVisible = true
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/.el-dialog__title {
  font-size: 14px;
}
/deep/.el-dialog__body {
  padding-top: 22px;
  .dialog-body {
    h3 {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      text-align: center;
    }

    .tips {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-top: 16px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;

      img {
        width: 48px;
        height: 48px;
      }
    }
  }
}
</style>
