<template>
  <div class="top-bar">
    <section class="top-box" :class="{ 'border-bottom': this.$route.path !== '/home' }">
      <div class="top">
        <CustomerService />
        <div class="greet">欢迎来到担担网</div>
        <div class="login" v-if="!$store.state.user.mobile" @click="login">请登录/注册</div>
        <div class="user" v-else>
          <div class="mobile">{{ $store.state.user.mobile | hideMobile }}</div>
          <div class="center" @click="toPersonalCenter">个人中心</div>
          <div class="out" @click="exit">退出</div>
        </div>
      </div>
    </section>
    <section
      class="search-box"
      ref="searchBox"
      :class="{ 'white-bg': this.$route.path !== '/home' || bgWhite, fixed: fixed }"
    >
      <div class="search">
        <div class="name" @click="$router.push('/home')">
          <img :class="[!bgWhite ? 'logo' : 'small-logo']" :src="!bgWhite ? Logo : SmallLogo" alt="" />
        </div>
        <el-input
          placeholder="输入你想要的商品"
          v-model="search"
          class="search-content"
          @keyup.enter.native="toProductList"
        >
          <img src="@/assets/images/topBar/search.png" slot="prefix" class="el-input__icon" alt="" />
          <div slot="append" @click="toProductList">搜 索</div>
        </el-input>
        <div class="release-info" v-login @click="$router.push('/personalCenter/publishSupplyInfo')">发布供应</div>
      </div>
      <slot name="top"></slot>
    </section>
  </div>
</template>

<script>
/* 顶栏组件 */
// 联系客服
import CustomerService from '@/components/CustomerService'
import Logo from '@/assets/images/common/logo.png' //网站logo完整
import SmallLogo from '@/assets/images/common/small_logo.png' //小的网站logo

export default {
  components: { CustomerService },
  data() {
    return {
      search: '',
      fixed: false, //是否定位
      bgWhite: false, //是否显示白色背景
      Logo,
      SmallLogo,
    }
  },
  created() {
    this.search = this.$route.query.search
  },
  mounted() {
    document.getElementsByClassName('layout-wrapper')[0].addEventListener('scroll', this.scrollToTop)
  },
  methods: {
    scrollToTop() {
      // 头部距离窗口顶部的距离
      let scrollTop = document.getElementsByClassName('top-bar')[0].getBoundingClientRect().top
      if (scrollTop <= -30) {
        this.fixed = true
        this.$refs.searchBox.style.minHeight = '56px'
        this.$refs.searchBox.style.height = `${128 + (scrollTop + 30)}px`
        this.bgWhite = true
      } else {
        this.fixed = false
        this.$refs.searchBox.style.minHeight = '128px'
        this.$refs.searchBox.style.height = `${56 + (scrollTop + 30)}px`
        this.bgWhite = false
      }
    },
    // 打开登录页面
    login() {
      this.$loginDialog.open()
    },
    // 跳转个人中心页面
    toPersonalCenter() {
      this.$router.push('/personalCenter')
    },
    // 去商品列表页面
    toProductList() {
      this.$router.push({
        path: '/productList',
        query: {
          pageSource: 'search',
          search: this.search,
        },
      })
    },
    // 退出
    exit() {
      this.$request
        .get('/login/logout')
        .then((data) => {
          this.$store.commit('user/REMOVE_USER_INFO')
          this.$message({
            type: 'success',
            message: '退出成功',
          })
        })
        .catch((data) => {
          this.$message({
            type: 'error',
            message: data.message || '退出失败',
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.top-bar {
  .top-box {
    height: 30px;
    background: #ffffff;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    .top {
      margin: 0 auto;
      width: 1200px;
      height: 100%;
      display: flex;
      align-items: center;

      .greet {
        padding: 0 24px 0 8px;
        border-left: 1px solid #eee;
      }
      .login {
        color: #3ba90e;
        cursor: pointer;
      }
      .user {
        display: flex;
        .center {
          margin: 0 8px;
          color: #3ba90e;
          cursor: pointer;
        }
        .out {
          color: #3ba90e;
          cursor: pointer;
        }
      }
    }
  }
  .border-bottom {
    border-bottom: 2px solid #f0f2f5;
  }

  .search-box {
    z-index: 1500;
    min-height: 56px;
    height: 128px;
    background: #efefef;
    display: flex;
    align-items: center;
    &.fixed {
      position: absolute;
      top: 0;
      width: 100%;
    }
    .search {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      .name {
        font-size: 40px;
        font-family: PangMenZhengDao;
        color: #3ba90e;
        text-align: center;
        margin-right: 64px;
        cursor: pointer;
        .small-logo {
          width: 121px;
          height: 41px;
        }
        .logo {
          width: 150px;
          height: 69px;
        }
      }
      /deep/ .search-content {
        width: 726px;
        height: 40px;
        border-radius: 8px;

        background: #fff;
        .el-input__inner {
          border: 2px solid #3ba90e;
          font-size: 16px;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          padding-left: 36px;
          border-right: none;
        }
        .el-input__prefix {
          padding-left: 6px;
          display: flex;
          align-items: center;
          .el-input__icon {
            width: 20px;
            height: 20px;
          }
        }
        .el-input-group__append {
          cursor: pointer;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          width: 90px;
          background: linear-gradient(180deg, #66c924 0%, #33a20a 100%);
          border: none;
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
          text-align: center;
        }
      }
      .release-info {
        cursor: pointer;
        width: 120px;
        height: 40px;
        border-radius: 8px;
        border: 2px solid #3ba90e;
        background: #ffffff;
        font-size: 18px;
        font-weight: bold;
        color: #3ba90e;
        text-align: center;
        line-height: 37px;
        margin-left: 24px;
      }
    }
  }
  .white-bg {
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }
}
</style>
