<template>
  <el-popover popper-class="customer-service-poper" :placement="placement" trigger="hover">
    <div class="qr-code">
      <img src="@/assets/images/topBar/qr_code.jpg" alt="" />
      <div class="title">
        微信扫描二维码 <br />
        官方客服，为你解答
      </div>
    </div>
    <slot slot="reference">
      <div class="contact"><img src="@/assets/images/topBar/contact.png" alt="" /> 联系客服</div>
    </slot>
  </el-popover>
</template>

<script>
// 联系客服二维码popover组件
export default {
  components: {},
  props: {
    // popover弹窗出现的位子
    placement: {
      type: String,
      default: 'bottom',
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
}
</script>
<style lang="scss">
.customer-service-poper {
  width: 192px;
  height: 167px;
  box-shadow: 0px 0px 11px 0px rgba(172, 172, 172, 0.18);
  border-radius: 8px;
  padding: 0;
  .popper__arrow {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
.qr-code {
  width: 192px;
  height: 167px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 88px;
    height: 85px;
    margin-bottom: 10px;
  }
  .title {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }
}
.contact {
  display: flex;
  align-items: center;
  width: 75px;
  cursor: pointer;
  img {
    width: 14px;
    height: 14px;
    margin-right: 3px;
  }
}
</style>
