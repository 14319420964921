<template>
  <div class="footer">
    <div class="nav">
      <div class="list-item" v-for="item in categorylist" :key="item.categoryType" @click="toListPage(item)">
        {{ item.name }}
      </div>
    </div>
    <div class="link">
      <div class="link-item">联系电话：0571-86799502</div>
      <div class="link-item" @click="go()">领见数农</div>
      <div class="link-item" @click="picPreview()">营业执照</div>
    </div>
    <p class="copyright">备案信息：<a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2021012646号-2</a></p>
    <div class="ewm">
      <img src="@/assets/images/home/ewm.jpg" alt="二维码" />
    </div>
    <p class="contact">联系我们</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categorylist: [
        // 农产品分类
        {
          img: require('@/assets/images/home/fruits.png'),
          name: '水果',
          categoryType: '1',
        },
        {
          img: require('@/assets/images/home/vegetables.png'),
          name: '蔬菜',
          categoryType: '2',
        },
        {
          img: require('@/assets/images/home/rice.png'),
          name: '粮油米面',
          categoryType: '3',
        },
        {
          img: require('@/assets/images/home/machining.png'),
          name: '农副加工',
          categoryType: '4',
        },
      ],
    }
  },
  created() {},
  methods: {
    // 跳转列表页-对应分类
    toListPage(item) {
      this.$router.push({
        name: 'ProductList',
        query: {
          tabCheckIndex: item.categoryType,
          pageSource: 'home',
        },
      })
    },
    // 预览营业执照
    picPreview() {
      let url = require('@/assets/images/home/business_license.png')
      window.open(url, '_blank')
    },
    // 跳转到官网
    go() {
      window.open('http://www.certifarm.cn/', '_blank')
    },
  },
}
</script>

<style scoped lang="scss">
.footer {
  margin-top: 56px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-top: 16px;
  padding-bottom: 16px;
  .nav {
    display: flex;
    margin-bottom: 16px;
    .list-item {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
      margin-right: 60px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
      &:hover {
        color: #3ba90e;
      }
    }
  }
  .link {
    display: flex;
    .link-item {
      padding: 0 12px;
      position: relative;
      font-size: 16px;
      &:nth-child(2),
      &:nth-child(3) {
        cursor: pointer;
        &:hover {
          font-weight: bold;
        }
      }
      &:last-child {
        &::after {
          width: 0;
        }
      }
      &::after {
        position: absolute;
        content: '';
        width: 1px;
        height: 13px;
        background: #333333;
        top: 5px;
        right: 0;
      }
    }
  }
  .copyright {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .ewm {
    width: 79px;
    height: 79px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .contact {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    margin-top: 6px;
  }
}
</style>
