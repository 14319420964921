/* 首页 */
const Home = () => import('@/views/home/Index') // 首页
const ProductDetails = () => import('@/views/productDetails/Index') // 商品详情页
const ProductList = () => import('@/views/productList/Index') // 商品大类列表页
export default [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页',
    },
  },
  {
    path: '/productdetails/:id',
    name: 'ProductDetails',
    component: ProductDetails,
    meta: {
      title: '商品详情',
    },
  },
  {
    path: '/productList',
    name: 'ProductList',
    component: ProductList,
    meta: {
      title: '商品列表',
    },
  },
]
