import Vue from 'vue'
import App from './App.vue'
// 引入 element-ui 组件（按需引入: 需要的组件在文件element-ui.js中开启）
import './element-ui.js'
// 引入 element-ui 自定义主题
import '../theme/index.css'
import router from './router'
import store from './store'
// 页面布局及顶部导航栏组件
import Layout from '@/components/Layout.vue'
Vue.component('Layout', Layout)

//登录弹窗
import loginDialog from '@/components/login/Index.js'
//添加登录指令 在需要登录的地方添加 v-login
Vue.directive('login', {
  bind: function (el) {
    // true能优先触发事件
    el.addEventListener('click', login, true)
  },
})
// 判断是否需要登录
function login(e) {
  if (!store.state.user.mobile) {
    // 用于取消所有后续事件捕获或事件冒泡，并阻止调用任何后续事件处理程序（DOM3 Events 中新增）
    e.stopImmediatePropagation()
    loginDialog.open()
  }
}

// 配置全局过滤器
import * as filters from './filters'
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

// 全局挂载Ajax请求方法
import request from './fetch/request'
Vue.prototype.$request = request

Vue.config.productionTip = false

// 监听路由跳转失败的情况
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  if (isChunkLoadFailed) {
    window.location.reload()
  }
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
