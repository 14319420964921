<template>
  <el-dialog
    :visible.sync="visible"
    width="414px"
    title="短信登录"
    :close-on-click-modal="false"
    custom-class="login-dialog"
    @closed="close"
  >
    <el-form key="form" :model="form" :rules="formRules" ref="form" label-width="65px">
      <el-form-item label="手机号" prop="mobile">
        <el-input v-model.trim="form.mobile" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="code">
        <el-input v-model.trim="form.code" placeholder="请输入验证码"> </el-input>
        <div @click="getCode" class="about-code" :class="{ 'code-active': sendCode }">
          <span>{{ countDown.start && !countDown.end ? `${countDown.number}s后重新获取` : '获取验证码' }}</span>
        </div>
      </el-form-item>
    </el-form>
    <div class="agreement">
      <el-checkbox-group v-model="agreement">
        <el-checkbox name="agreement"></el-checkbox>
      </el-checkbox-group>
      <div class="agreement-content">
        登录即表示同意
        <a
          target="_blank"
          href="https://certifarm-public.oss-cn-hangzhou.aliyuncs.com/agriculturalProductInformation/documents/%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE.pdf"
          class="agreement-name"
          >《用户协议》</a
        >
        <a
          target="_blank"
          href="https://certifarm-public.oss-cn-hangzhou.aliyuncs.com/agriculturalProductInformation/documents/%E9%9A%90%E7%A7%81%E5%8D%8F%E8%AE%AE.pdf"
          class="agreement-name"
          >《隐私协议》</a
        >
      </div>
    </div>
    <el-button type="primary" :disabled="!valid" @click="login">登录</el-button>
  </el-dialog>
</template>

<script>
import countDown from './countDown.worker'
import regexp from '@/utils/regexp.js'
import store from '@/store'
import CertifiedSucDialog from '@/components/CertifiedSuc/index' // 认证成功弹窗
export default {
  components: {},
  props: {},
  data() {
    // 验证手机号
    const validatorMobile = (rule, value, callback) => {
      if (regexp.mobileReg.test(value)) {
        callback()
        return
      }
      callback(new Error('请输入正确的手机号'))
    }
    // 验证码
    const validatorCode = (rule, value, callback) => {
      if (regexp.codeReg.test(value)) {
        callback()
        return
      }
      callback(new Error('请输入 6 位数字验证码'))
    }
    return {
      visible: false,
      sendCode: false, //是否可以发送验证码
      countDownOb: null,
      countDown: {
        start: false,
        number: 60,
        end: false,
      },
      agreement: [], //协议勾选
      form: {
        mobile: '',
        code: '',
      },
      // 表单验证规则
      formRules: {
        mobile: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur',
          },
          {
            validator: validatorMobile,
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur',
          },
          {
            validator: validatorCode,
            trigger: 'blur',
          },
        ],
      },
    }
  },
  computed: {
    valid() {
      const { mobile, code } = this.form
      if (mobile && code && this.agreement.length) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    'form.mobile': function (newVal) {
      this.sendCode = regexp.mobileReg.test(newVal)
    },
    'countDown.end': function (end) {
      if (end) {
        this.resetCountDown()
      }
    },
  },
  methods: {
    open() {
      this.visible = true
    },
    //serviceworker 倒计时
    contactWorker(fn) {
      this.countDownOb = new countDown()
      this.countDownOb.postMessage({ start: true })
      this.countDownOb.onmessage = fn
    },
    // 重置验证码
    resetCountDown() {
      this.sendCode = true
      this.countDown = {
        start: false,
        number: 60,
        end: false,
      }
    },
    // 发送验证码
    sendCodeRequest() {
      return this.$request
        .post('login/sendSms', {
          params: {
            mobile: this.form.mobile,
          },
        })
        .then(() => true)
        .catch((data) => {
          return Promise.reject()
        })
    },
    // 获取验证码
    getCode() {
      if (!this.sendCode) return
      this.$refs.form.clearValidate('code')
      this.sendCodeRequest().then((status) => {
        if (status) {
          this.$message({
            type: 'success',
            message: '验证码发送成功',
          })
          this.sendCode = false
          this.countDown.start = true
          this.contactWorker((count) => {
            const countType = typeof count.data
            if (countType === 'number') {
              this.countDown.number = count.data
            } else {
              this.countDown.end = count.data.end
            }
          })
        }
      })
    },
    login() {
      this.$refs.form.validate((validator) => {
        if (!validator) return
        this.$request
          .post('/login/login', {
            params: { ...this.form },
          })
          .then(async (data) => {
            this.visible = false
            await store.commit('user/SET_TOKEN', data.token)
            await store.commit('user/SET_MOBILE', data.mobile)
            await store.commit('user/SET_ROLE_ENTITIES', data.roleEntities)
            // 判断用户是否需要认证成功弹窗
            const isPopup = await store.dispatch('getUserhasCertified')
            isPopup && CertifiedSucDialog.open()
            this.$message({
              type: 'success',
              message: '登录成功',
            })
            this.$emit('isLogin')
          })
          .catch((data) => {
            this.$message({
              type: 'error',
              message: data.message || '登陆失败',
            })
          })
      })
    },
    // 关闭时重置
    close() {
      this.sendCode = false
      // 关闭倒计时线程
      if (this.countDownOb) {
        this.countDownOb.terminate()
      }
      this.countDownOb = null
      this.countDown = {
        start: false,
        number: 60,
        end: false,
      }
      this.agreement = []
      this.form = {
        mobile: '',
        code: '',
      }
      this.$refs.form.clearValidate()
    },
  },
  created() {},
  mounted() {},
}
</script>
<style lang="scss" scoped>
/deep/ .login-dialog {
  box-shadow: 0px 9px 24px 0px rgba(30, 45, 102, 0.06);
  border-radius: 10px;
  .el-dialog__header {
    padding: 32px 20px 0 40px;
    .el-dialog__title {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #434343;
    }
    .el-dialog__headerbtn {
      top: 16px;
      right: 16px;
      .el-dialog__close {
        color: #aaa;
        font-weight: 500;
        font-size: 20px;
      }
    }
  }
  .el-dialog__body {
    padding: 50px 40px 45px;
  }
  .agreement {
    display: flex;
    margin-bottom: 10px;
    .agreement-content {
      padding-left: 10px;
      .agreement-name {
        cursor: pointer;
        color: #3ba90e;
      }
    }
  }
}
::v-deep .el-form-item__content {
  width: 100%;
  position: relative;

  .about-code {
    padding: 0 0 0 15px;
    height: 32px;
    border-radius: 2px;
    border: none;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #aaaaaa;
    position: absolute;
    text-align: center;
    line-height: 30px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    &.code-active {
      color: #3ba90e;
      cursor: pointer;
    }
  }
}
/deep/ .el-button {
  display: block;
  width: 100%;
  height: 40px;
  background: linear-gradient(180deg, #66c924 0%, #33a20a 100%);
  border: none;
  border-radius: 4px;
  padding: 0;

  span {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 28px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    bottom: 0;
  }
  &.is-disabled {
    background: #aaaaaa;
  }
}

/deep/ .el-form {
  margin-bottom: 53px;
  .el-form-item {
    display: flex;
    border-bottom: 1px solid #dddddd;
    align-items: center;
    margin-bottom: 32px;

    &.is-error {
      border-bottom: 1px solid #f56c6c;
    }

    .el-form-item__label {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
      text-align: left;

      &:before {
        display: none;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-form-item__content {
    margin-left: 0 !important;
  }
  .el-input-group__append {
    background-color: #ffffff;
    border: 1px solid #ffffff;
  }
  .el-input {
    input {
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 22px;

      &::placeholder {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ddd;
        line-height: 22px;
      }

      &:-webkit-autofill::first-line {
        font-size: 16px;
      }
    }
  }

  .el-form-item__error {
    left: -62px;
    margin-top: 5px;
  }
}
</style>
