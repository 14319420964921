<template>
  <div id="app">
    <!-- 如果不是个人中心页面router-view添加key -->
    <router-view v-if="$route.fullPath.indexOf('personalCenter') === -1" :key="$route.fullPath" />
    <router-view v-else />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },
  methods: {},
  mounted() {},
  // watch: {},
}
</script>

<style lang="scss">
[v-cloak] {
  display: none;
}

html {
  box-sizing: border-box;
}

#app {
  width: 100vw;
  height: 100vh;
  background: #efefef;
}

* {
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: PingFangSC-Regular, PingFang SC !important;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  text-align: left;
  background: #fff;
  color: #333;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:focus,
a:active {
  outline: none;
}

a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.clearfix {
  &:after {
    content: '';
    height: 0;
    display: block;
    visibility: hidden;
    font-size: 0;
    clear: both;
  }
}
</style>
