<template>
  <div class="layout-wrapper">
    <TopBar ref="topBar"></TopBar>
    <div class="layout-content">
      <slot></slot>
      <!-- 底部 -->
      <Footer></Footer>
    </div>
    <BackTop />
  </div>
</template>

<script>
/* 全局布局组件 */
import TopBar from './topBar/Index'
import BackTop from './backTop/Index'
import Footer from './Footer.vue'
export default {
  name: 'Layout',
  components: {
    TopBar,
    BackTop,
    Footer,
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.layout-wrapper {
  min-width: 1200px;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .layout-content {
    height: calc(100% - 158px);
    background: transparent;
  }
}
</style>
