/* 个人中心 */
const PersonalCenter = () => import('@/views/personalCenter/Index') // 个人中心
const PublishList = () => import('@/views/personalCenter/publishMgmt/publishList/Index') // 发布列表
const PublishSupplyInfo = () => import('@/views/personalCenter/publishMgmt/publishSupplyInfo/Index') // 发布供应信息
const PersonalInfo = () => import('@/views/personalCenter/personalInfo/Index') // 个人信息
const GoodsAudit = () => import('@/views/personalCenter/goodsAudit/Index') // 商品审核
const MerchantAudit = () => import('@/views/personalCenter/merchantAudit/Index') // 商家审核

export default {
  path: '/personalCenter',
  name: 'PersonalCenter',
  component: PersonalCenter,
  redirect: '/personalCenter/publishList',
  meta: {
    title: '个人中心',
  },
  children: [
    {
      path: '',
      name: 'PublishMgmt',
      redirect: '/personalCenter/publishList',
      meta: {
        title: '发布管理',
        iconName: 'shangpinshenhe',
      },
    },
    {
      path: '/personalCenter/publishList',
      name: 'PublishList',
      component: PublishList,
      meta: {
        title: '发布列表',
        parent: 'PublishMgmt',
      },
    },
    {
      path: '/personalCenter/publishSupplyInfo',
      name: 'PublishSupplyInfo',
      component: PublishSupplyInfo,
      meta: {
        title: '发布供应信息',
        parent: 'PublishMgmt',
      },
    },
    {
      path: '/personalCenter/personalInfo',
      name: 'PersonalInfo',
      component: PersonalInfo,
      meta: {
        title: '个人信息',
        iconName: 'gerenzhongxin',
      },
    },
    {
      path: '/personalCenter/goodsAudit',
      name: 'GoodsAudit',
      component: GoodsAudit,
      meta: {
        title: '商品审核',
        iconName: 'gongyingguanli',
        auth: true, //需要权限
      },
    },
    {
      path: '/personalCenter/merchantAudit',
      name: 'MerchantAudit',
      component: MerchantAudit,
      meta: {
        title: '商家审核',
        iconName: 'gerenzhongxin',
        auth: true, //需要权限
      },
    },
  ],
}
