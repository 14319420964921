import dayjs from 'dayjs'
export function formatTime(time) {
  // 格式化时间
  if (!time) return ''
  return dayjs(time).format('YYYY-MM-DD')
}
// 手机号中间四位变*号
export function hideMobile(mobile) {
  return mobile.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
}
