import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import home from './home' // 首页版块路由
import personalCenter from './personalCenter' // 个人中心
import CertifiedSucDialog from '@/components/CertifiedSuc/index' // 认证成功弹窗
import store from '@/store'
import { Message } from 'element-ui'
const routes = [
  {
    path: '/',
    meta: {},
    redirect: '/home',
  },
  ...home,
  {
    ...personalCenter,
  },
  {
    path: '*',
    redirect: '/home',
  },
]
// 防止跳转同一路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  scrollBehavior: () => ({
    y: 0,
  }),
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.afterEach(async () => {
  // 用户已登录
  if (store.state.user.mobile) {
    // 判断用户是否需要认证成功弹窗
    const isPopup = await store.dispatch('getUserhasCertified')
    isPopup && CertifiedSucDialog.open()
  }
})
router.beforeEach(async (to, from, next) => {
  if (store.state.user.roleEntities && store.state.user.roleEntities.name !== '管理员' && to.meta.auth) {
    //如果不是管理员并且去的页面需要权限
    Message({
      type: 'error',
      message: '你暂无权限',
    })
    next(from.path)
  } else if (
    store.state.user.roleEntities &&
    store.state.user.roleEntities.name === '管理员' &&
    to.path.indexOf('personalCenter') !== -1 &&
    !to.meta.auth
  ) {
    // 如果角色为管理员并且重定向来源为personalCenter 则重定向到管理员个人中心页面 防止重定向到默认页面
    next('/personalCenter/goodsAudit')
  } else {
    next()
  }
})
export default router
