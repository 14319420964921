<template>
  <div class="back-top">
    <div class="back-top-content">
      <div class="back-top-item top" v-if="gotop" @click="toTop">
        <img src="./assets/top.png" alt="" />
        <div class="title">回顶部</div>
      </div>
      <CustomerService placement="left">
        <div class="back-top-item qr-code">
          <img src="./assets/phone.png" alt="" />
          <div class="title">联系客服</div>
        </div>
      </CustomerService>
    </div>
  </div>
</template>

<script>
/* 回到顶部 组件*/
// 联系客服
import CustomerService from '@/components/CustomerService'
export default {
  components: { CustomerService },
  props: {},
  data() {
    return {
      gotop: false, //是否显示回到顶部
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleScroll() {
      let scrolltop = document.getElementsByClassName('layout-wrapper')[0].scrollTop || document.body.scrollTop
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false)
    },
    toTop() {
      let top = document.getElementsByClassName('layout-wrapper')[0].scrollTop || document.body.scrollTop
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.getElementsByClassName('layout-wrapper')[0].scrollTop = top -= 50
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 10)
    },
  },
  created() {},
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
}
</script>
<style lang="scss" scoped>
.back-top {
  @keyframes fadeIn {
    0% {
      opacity: 0; /*初始状态 透明度为0*/
    }
    100% {
      opacity: 1; /*结尾状态 透明度为1*/
    }
  }
  &-content {
    z-index: 2;
    position: fixed;
    bottom: 69px;
    right: 24px;
    width: 80px;
    background: #ffffff;
    box-shadow: 0px 0px 11px 0px rgba(172, 172, 172, 0.18);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .back-top-item {
      cursor: pointer;
      height: 88px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      animation: fadeIn 1s;
      .title {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      img {
        width: 24px;
        height: 24px;
        margin-bottom: 7px;
      }
    }
  }
}
</style>
